import * as types from './Types'

const initailState = {
    B2CUser: [],
    ActiveUser: {},
    loading: true
}

export const b2cReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_B2C_USER: return {
            ...state,
            B2CUser: payload,
            loading: false
        }
        case types.ACTIVE_NEW_USER: return {
            ...state,
            ActiveUser: payload,
            loading: false
        }
        default:
            return state
    }
}

export default b2cReducers