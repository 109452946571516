import * as types from './Types'

const initialState = {
    AllCountry: [],
    AllState: [],
    CountryBasedState: [],
    AllCity: [],
    ActiveCountry: [],
    loading: true
}

export const masterPosPayoutReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_COUNTRY:
            return {
                ...state,
                AllCountry: payload,
                loading: false
            }
        case types.ADD_COUNTRY:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_COUNTRY:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_COUNTRY:
            return {
                ...state,
                loading: false
            }
        // *************************************************************** State

        default:
            return state
    }
}

export default masterPosPayoutReducers