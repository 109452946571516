
// *************************************** Region  ******************

export const GET_ALL_REGIONS = " GET_ALL_REGIONS"
export const GET_SINGLE_REGION = " GET_SINGLE_REGION"
export const ADD_REGION = " ADD_REGION"
export const UPDATE_REGION = " UPDATE_REGION"
export const DELETE_REGION = " DELETE_REGION"

// *************************************** Branch  ******************

export const GET_ALL_BRANCH = 'GET_ALL_BRANCH'
export const GET_ALL_ACTIVE_RESION = 'GET_ALL_ACTIVE_RESION'
export const GET_SINGLE_BRANCH = "GET_SINGLE_BRANCH"
export const ADD_BRANCH = "ADD_BRANCH"
export const UPDATE_BRANCH = "UPDATE_BRANCH"
export const DELETE_BRANCH = "DELETE_BRANCH"

