import * as types from './type'

const initialState = {
    allPayment: {},

    loading: true
}
export const PaymentReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_PAYMENT_DETAILS: return {
            ...state,
            allPayment: payload,
            loading: false
        }

        default: return state
    }
}
export default PaymentReducers