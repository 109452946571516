import * as types from './Type'

const initailState = {
    AllLife: [],
    loading: true
}
export const LifeReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_LIFE: return {
            ...state,
            AllLife: payload,
            loading: false
        }
   
        default: return state
    }
}
export default LifeReducers