import * as types from './type'

const initialState = {
    allActiveRole: [],
    AllTeams: [],
    AllManager: [],
    loading: true

}
export const posPayoutReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_ACTIVE_ROLE: return {
            ...state,
            allActiveRole: payload,
            loading: false
        }
        case types.GET_ALL_TEAM_ROLE: return {
            ...state,
            AllTeams: payload,
            loading: false
        }
        case types.GET_ALL_MANAGER_ROLE: return {
            ...state,
            AllManager: payload,
            loading: false
        }
        default:
            return state
    }
}
export default posPayoutReducers