import * as types from './Types'

const initailState = {
    Products: [],
    loading: true
}

export const HomeReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_PRODUCT:
            return {
                ...state,
                Products: payload,
                loading: false
            }
        default:
            return state
    }
}

export default HomeReducers