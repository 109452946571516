import * as types from './Types'
const initialState = {
    AllMenu: [],
    AllSubMenu: [],
    ActiveModule: [],
    AllRoles: [],
    ActiveRole: [],
    AllTeams: [],
    AllPermission: [],
    AllMasters: [],
    AllQualification: [],
    FuleType: [],
    RtoType: [],
    RtoState: [],
    RtoCity: [],
    VehicleType: [],
    InsurerType: [],
    PreviousInsurer: [],
    ActiveInsurer: [],
    Occupation: [],
    RelationShip: [],
    Products: [],
    AllFinancer: [],
    SingleFinancer: [],
    AllDepartment: [],
    loading: true
}

const SystemConfigReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {

        // ******************************************************* All Menu
        case types.GET_ALL_MENUS:
            return {
                ...state,
                AllMenu: payload,
                loading: false
            }
        case types.GET_SINGLE_MENU:
            return {
                ...state,
                SingleMenu: payload,
                loading: false
            }
        case types.ADD_MENU:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_MENU:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_MENU:
            return {
                ...state,
                loading: false
            }
        // ******************************************************* Sub Menu 
        case types.GET_ALL_SUB_MENUS:
            return {
                ...state,
                AllSubMenu: payload,
                loading: false
            }
        case types.GET_SINGLE_SUB_MENU:
            return {
                ...state,
                SingleSubMenu: payload,
                loading: false
            }
        case types.ADD_SUB_MENU:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_SUB_MENU:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_SUB_MENU:
            return {
                ...state,
                loading: false
            }
        case types.GET_ALL_ACTIVE_MODULE:
            return {
                ...state,
                ActiveModule: payload,
                loading: false
            }
        // ******************************************************* Permission 
        case types.GET_ALL_PERMISSIONS:
            return {
                ...state,
                AllPermission: payload,
                loading: false
            }
        case types.GET_SINGLE_PERMISSION:
            return {
                ...state,
                SingleCategory: payload,
                loading: false
            }
        case types.ADD_PERMISSION:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_PERMISSION:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_PERMISSION:
            return {
                ...state,
                loading: false
            }
        // *******************************************************  Master Series 
        case types.GET_ALL_MASTERS_SERIES:
            return {
                ...state,
                AllMasters: payload,
                loading: false
            }
        case types.ADD_MASTER_SERIES:
            return {
                ...state,
                loading: false
            }
        case types.GET_SINGLE_MASTER_SERIES:
            return {
                ...state,
                SingleMaster: payload,
                loading: false
            }
        case types.UPDATE_MASTER_SERIES:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_MASTER_SERIES:
            return {
                ...state,
                loading: false
            }
        // *******************************************************  Fuel
        case types.GET_ALL_FUEL:
            return {
                ...state,
                FuleType: payload,
                loading: false
            }
        case types.GET_SINGLE_FUEL:
            return {
                ...state,
                SingleFuel: payload,
                loading: false
            }
        case types.ADD_FUEL:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_FUEL:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_FUEL:
            return {
                ...state,
                loading: false
            }
        // *******************************************************  Vehicle Type
        case types.GET_ALL_VEHICLE:
            return {
                ...state,
                VehicleType: payload,
                loading: false
            }
        case types.GET_SINGLE_VEHICLE:
            return {
                ...state,
                SingleVehicle: payload,
                loading: false
            }
        case types.ADD_VEHICLE:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_VEHICLE:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_VEHICLE:
            return {
                ...state,
                loading: false
            }
        // *******************************************************  Insurer
        case types.GET_ALL_INSURER:
            return {
                ...state,
                InsurerType: payload,
                loading: false
            }
        case types.GET_SINGLE_INSURER:
            return {
                ...state,
                SingleInsurer: payload,
                loading: false
            }
        case types.ADD_INSURER:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_INSURER:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_INSURER:
            return {
                ...state,
                loading: false
            }

        case types.GET_ALL_ACTIVE_INSURER:
            return {
                ...state,
                ActiveInsurer: payload,
                loading: false
            }
        // *******************************************************  Products
        case types.GET_ALL_PRODUCTS:
            return {
                ...state,
                Products: payload,
                loading: false
            }
        case types.GET_SINGLE_PRODUCTS:
            return {
                ...state,
                SingleProducts: payload,
                loading: false
            }
        case types.ADD_PRODUCTS:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_PRODUCTS:
            return {
                ...state,
                loading: false
            }
        case types.DELETE_PRODUCTS:
            return {
                ...state,
                loading: false
            }

        default:
            return state
    }
}
export default SystemConfigReducers