export const GET_ALL_POS = "GET_ALL_POS"
export const GET_SINGLE_POS = "GET_SINGLE_POS"
export const UPDATE_POS_STATUS = "UPDATE_POS_STATUS"
export const DELETE_POS = "DELETE_POS"
export const COUNT_POS_STATUS = "COUNT_POS_STATUS"
export const GET_REJECT_REASON = "GET_REJECT_REASON"

// modify
export const GET_EDUCATION = "GET_EDUCATION"
export const PERSONAL_INFO = "PERSONAL_INFO"
export const PAN_INFO = "PAN_INFO"
export const AADHAAR_INFO = "AADHAAR_INFO"
export const ADDRESS_INFO = "ADDRESS_INFO"
export const GET_ALL_CITY = "GET_ALL_CITY"
export const UPDATE_POS = "UPDATE_POS"