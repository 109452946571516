
// ********************************************************************** B2C

export const GET_ALL_INSURER_PRIV = "GET_ALL_INSURER_PRIV"
export const ADD_NEW_INSURER_PRIV = "ADD_NEW_INSURER_PRIV"
export const DELETE_INSURER_PRIV = "DELETE_INSURER_PRIV"
export const UPDATE_INSURER_PRIV = "UPDATE_INSURER_PRIV"

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS"
export const GET_ALL_IINSURER_LIST = "GET_ALL_IINSURER_LIST"

