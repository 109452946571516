import * as types from './Types'

const initailState = {
    products: [],
    totalBusiness: [],
    productWise: [],
    PolicyWise: [],
    InsurerWise: [],
    examSchedule: {},
    loading: true
}

export const DashboardReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_PRODUCTS:
            return {
                ...state,
                products: payload,
                loading: false
            }
        case types.GET_EXAM_SCHEDULE:
            return {
                ...state,
                examSchedule: payload,
                loading: false
            }
        case types.GET_TOTAL_BUSINESS: return {
            ...state,
            totalBusiness: payload,
            loading: false
        }
        case types.GET_CHART_PRODUCT_WISE: return {
            ...state,
            productWise: payload,
            loading: false
        }
        case types.GET_POLCIY_PRODUCT_WISE: return {
            ...state,
            PolicyWise: payload,
            loading: false
        }
        case types.GET_POLCIY_INSURER_WISE: return {
            ...state,
            InsurerWise: payload,
            loading: false
        }
        default:
            return state
    }
}

export default DashboardReducers