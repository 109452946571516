

export const GET_BUTTON_PRIVILEGE = "GET_BUTTON_PRIVILEGE"
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS"
export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_TOTAL_COUNTED_USERS_STATUS = "GET_TOTAL_COUNTED_USERS_STATUS"
export const GET_ALL_ROLES = "GET_ALL_ROLES"
export const GET_ROLEBASED_PRIV = "GET_ROLEBASED_PRIV"
export const GET_TEAM_ROLE_BASED = "GET_TEAM_ROLE_BASED"
export const GET_ALL_MANAGER = "GET_ALL_MANAGER"
export const GET_ALL_REGION = "GET_ALL_REGION"
export const GET_SINGLE_REGION = "GET_SINGLE_REGION"
export const GET_ALL_BRANCH = "GET_ALL_BRANCH"
export const GET_SINGLE_BRANCH = "GET_SINGLE_BRANCH"
export const GET_ALL_QUALIFICATION = "GET_ALL_QUALIFICATION"
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_PERSONALINFO = "ADD_PERSONALINFO"
export const UPDATE_PERSONALINFO = "ADD_PERSONALINFO"
export const UPDATE_ADDRESSINFO = "ADD_ADDRESSINFO"
export const UPDATE_DOCUMENTINFO = "UPDATE_DOCUMENTINFO"
export const UPDATE_PRIVILGEINFO = "UPDATE_PRIVILGEINFO"
export const UPDATE_PAYMENTINFO = "UPDATE_PAYMENTINFO"

export const ADD_DATA_PERSONALINFO = "ADD_DATA_PERSONALINFO"
export const ADD_DATA_ADDRESSINFO = "ADD_DATA_ADDRESSINFO"
export const ADD_DATA_DOCUMENTINFO = "ADD_DATA_DOCUMENTINFO"
export const ADD_DATA_PRIVILLEGEINFO = "ADD_DATA_PRIVILLEGEINFO"
export const ADD_DATA_PAYMENTINFO = "ADD_DATA_PAYMENTINFO"
export const GET_PID_ARRAY = "GET_PID_ARRAY"

export const GET_ACTIVE_DEPT = "GET_ACTIVE_DEPT"
export const RESER_STORE_DATA = "RESER_STORE_DATA"
export const RESER_MANAGER_DATA = "RESER_MANAGER_DATA"