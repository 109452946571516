
// ********************************************************************** Make
export const GET_ALL_MAKE = "GET_ALL_MAKE"
export const GET_SINGLE_MAKE = "GET_SINGLE_MAKE"
export const ADD_MAKE = "ADD_MAKE"
export const UPDATE_MAKE = "UPDATE_MAKE"
export const DELETE_MAKE = "DELETE_MAKE"

// ********************************************************************** Model
export const GET_ALL_MODEL = "GET_ALL_MODEL"
export const GET_SINGLE_MODEL = "GET_SINGLE_MODEL"
export const ADD_MODEL = "ADD_MODEL"
export const UPDATE_MODEL = "UPDATE_MODEL"
export const DELETE_MODEL = "DELETE_MODEL"

// ********************************************************************** Variant
export const GET_ALL_VARIANT = "GET_ALL_VARIANT"
export const GET_ALL_VARIANT_VEHICLE = "GET_ALL_VARIANT_VEHICLE"
export const GET_ALL_ACTIVE_FUEL = "GET_ALL_ACTIVE_FUEL"
export const GET_SINGLE_VARIANT = "GET_SINGLE_VARIANT"
export const ADD_VARIANT = "ADD_VARIANT"
export const UPDATE_VARIANT = "UPDATE_VARIANT"
export const DELETE_VARIANT = "DELETE_VARIANT"


// *************************** Bases On ***************************

export const GET_ALL_MODEL_BASED = "GET_ALL_MODEL_BASED"
export const GET_ALL_ACTIVE_MAKE = "GET_ALL_ACTIVE_MAKE"