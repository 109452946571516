import * as types from './Types'

const initailState = {
    ActiveInsurerPriv: [],
    ActiveProducts: [],
    ActiveProductList: [],
    UpdatedData: [],
    loading: true
}

export const InsurerPrivReducer = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_INSURER_PRIV: return {
            ...state,
            ActiveInsurerPriv: payload,
            loading: false
        }
        case types.GET_ALL_PRODUCTS: return {
            ...state,
            ActiveProducts: payload,
            loading: false
        }
        case types.GET_ALL_IINSURER_LIST: return {
            ...state,
            ActiveProductList: payload,
            loading: false
        }
        case types.UPDATE_INSURER_PRIV: return {
            ...state,
            UpdatedData: payload,
            loading: false
        }
        default:
            return state
    }
}

export default InsurerPrivReducer