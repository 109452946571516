export const MENU_ACCESS = "MENU_ACCESS"
export const GET_USER_DETAIL_BASED_ID = "GET_USER_DETAIL_BASED_ID"

// ******************************************** Create POS

export const PERSONAL_INFO = "PERSONAL_INFO"
export const PAN_INFO = "PAN_INFO"
export const AADHAAR_INFO = "AADHAAR_INFO"
export const ADDRESS_INFO = "ADDRESS_INFO"

export const GET_EDUCATION = "GET_EDUCATION"
export const GET_ALL_CITY = "GET_ALL_CITY"
export const ADD_NEW_POS = "ADD_NEW_POS"
export const EMAIL_VERIFY = "EMAIL_VERIFY"
export const GET_LIFE_DATA = "GET_LIFE_DATA"
export const GET_ALL_LIFE_DATA = "GET_ALL_LIFE_DATA"

export const RESET_DATA = "RESET_DATA"

