import * as types from './ReqType'

const initialState = {
    getAllLead: [],
    getAllActiveInsurer: [],
    loading: true
}

export const RequestResponseReducers = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case types.GET_ALL_LEADS:
            return {
                ...state,
                getAllUsers: payload,
                loading: false
            }
        case types.GET_ALL_INSURER:
            return {
                ...state,
                getAllActiveInsurer: payload,
                loading: false
            }
        default:
            return state
    }
} 