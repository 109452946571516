
// ********************************************************************* Qualification
export const GET_ALL_QUALIFICATION = "GET_ALL_QUALIFICATION"
export const GET_SINGLE_QUALIFICATION = "GET_SINGLE_QUALIFICATION"
export const ADD_QUALIFICATION = "ADD_QUALIFICATION"
export const UPDATE_QUALIFICATION = "UPDATE_QUALIFICATION"
export const DELETE_QUALIFICATION = "DELETE_QUALIFICATION"
// ********************************************************************* Occupation
export const GET_ALL_OCCUPATION = "GET_ALL_OCCUPATION"
export const GET_SINGLE_OCCUPATION = "GET_SINGLE_OCCUPATION"
export const ADD_OCCUPATION = "ADD_OCCUPATION"
export const UPDATE_OCCUPATION = "UPDATE_OCCUPATION"
export const DELETE_OCCUPATION = "DELETE_OCCUPATION"
// ********************************************************************* Map Roles
export const GET_ALL_ROLES = "GET_ALL_ROLES"
export const GET_SINGLE_ROLE = "GET_SINGLE_ROLE"
export const ADD_ROLE = "ADD_ROLE"
export const UPDATE_ROLE = "UPDATE_ROLE"
export const DELETE_ROLE = "DELETE_ROLE"
// ********************************************************************* Map Team
export const GET_ALL_ACTIVE_ROLE = "GET_ALL_ACTIVE_ROLE"
export const GET_ALL_TEAMS = "GET_ALL_TEAMS"
export const GET_SINGLE_TEAM = "GET_SINGLE_TEAM"
export const ADD_TEAM = "ADD_TEAM"
export const UPDATE_TEAM = "UPDATE_TEAM"
export const DELETE_TEAM = "DELETE_TEAM"
// ********************************************************************* RTO
export const GET_ALL_RTO = "GET_ALL_RTO"
export const GET_ALL_RTO_STATE = "GET_ALL_RTO_STATE"
export const GET_ALL_RTO_CITY = "GET_ALL_RTO_CITY"
export const GET_SINGLE_RTO = "GET_SINGLE_RTO"
export const ADD_RTO = "ADD_RTO"
export const UPDATE_RTO = "UPDATE_RTO"
export const DELETE_RTO = "DELETE_RTO"
export const GET_ALL_ACTIVE_STATE = "GET_ALL_ACTIVE_STATE"
export const GET_ALL_ACTIVE_CITY = "GET_ALL_ACTIVE_CITY"

// *********************************************************************Active  Insurer
export const GET_ALL_ACTIVE_INSURER = "GET_ALL_ACTIVE_INSURER"
// ********************************************************************* RelationShip
export const GET_ALL_RELATIONS = "GET_ALL_RELATIONS"
export const GET_SINGLE_RELATIONS = "GET_SINGLE_RELATIONS"
export const ADD_RELATIONS = "ADD_RELATIONS"
export const UPDATE_RELATIONS = "UPDATE_RELATIONS"
export const DELETE_RELATIONS = "DELETE_RELATIONS"
// ********************************************************************* Previous Insurer
export const GET_ALL_PREVIOUS_INSURER = "GET_ALL_PREVIOUS_INSURER"
export const GET_SINGLE_PREVIOUS_INSURER = "GET_SINGLE_PREVIOUS_INSURER"
export const ADD_PREVIOUS_INSURER = "ADD_PREVIOUS_INSURER"
export const UPDATE_PREVIOUS_INSURER = "UPDATE_PREVIOUS_INSURER"
export const DELETE_PREVIOUS_INSURER = "DELETE_PREVIOUS_INSURER"

// ********************************************************************* Finaners
export const GET_ALL_FINANCER = "GET_ALL_FINANCER"
export const GET_SINGLE_FINANCER = "GET_SINGLE_FINANCER"
export const ADD_FINANCER = "ADD_FINANCER"
export const UPDATE_FINANCER = "UPDATE_FINANCER"
export const DELETE_FINANCER = "DELETE_FINANCER"
// ********************************************************************* Department
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT"
export const GET_SINGLE_DEPARTMENT = "GET_SINGLE_DEPARTMENT"
export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT"
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT"
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT"
export const GET_ALL_ACTIVE_DEPARTMENT = "GET_ALL_ACTIVE_DEPARTMENT"